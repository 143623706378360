import { images } from '@/asset';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import WarningBanner from '@/components/WarningBanner';
import CustomCard from '@/components/customCard';
import Layout from '@/components/layout';
import { config } from '@/config';
import { openCrisp } from '@/helpers';
import { apiCaller } from '@/redux/query';
import bannerSlice, {
  BannerSliceKeys,
  embeddedBannerSelector,
  isVisibleBannerBlockify,
  isVisibleRecommendAppsSelector,
} from '@/redux/slice/banner.slice';
import { dashboardDataSelector } from '@/redux/slice/dashboard.slice';
import { isVisibleThinkTodos } from '@/redux/slice/thinksToDo.slice';
import { Button, Card, Divider, Icon, InlineGrid, Link, SkeletonBodyText, Text, Tooltip } from '@shopify/polaris';
import {
  ButtonIcon,
  ChannelsIcon,
  ChatIcon,
  MenuHorizontalIcon,
  PlanIcon,
  QuestionCircleIcon,
  SendIcon,
  SmileyHappyIcon,
  XIcon,
} from '@shopify/polaris-icons';
import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../Widgets/component/Table';
import Analytics from './components/Analytics';
import ThingsToDoNext from './components/ThingsToDoNext';
import RecommendedApps from './components/recommendedApps';
import { HomeStyled } from './styled';
import { capitalizeFirstLetter } from '@/helpers/string';
import HolidayBanner from '@/components/HolidayBanner';

interface ActionItemProps {
  content: React.ReactNode;
  prefix: React.ReactNode;
  isHiddenDivider?: boolean;
  disable?: boolean;
  onAction?: () => void;
}

const HomePage = () => {
  const embeddedBanner = useSelector(embeddedBannerSelector);
  const dashboard = useSelector(dashboardDataSelector);
  const isVisibleThinkTodoNext = useSelector(isVisibleThinkTodos);
  const isVisibleRecommendApps = useSelector(isVisibleRecommendAppsSelector);
  const isVisibleBlockify = useSelector(isVisibleBannerBlockify);
  const { data, isLoading } = apiCaller.useGeneralSettingsQuery();
  const [isShowEmbedBanner, setIsShowEmbedBanner] = useState(false);
  const [getEmbeddedAppStatus] = apiCaller.useLazyGetEmbeddedAppStatusQuery();

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isVisibleThinkTodoNext) {
      const timer = setTimeout(() => {
        getEmbeddedAppStatus().then((res) => {
          if (res.data?.data) {
            setIsShowEmbedBanner(!res.data.data.isAppEnabled);
          }
        });
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const ActionItems = ({ content, prefix, isHiddenDivider, disable, onAction }: ActionItemProps) => {
    return (
      <div style={{ cursor: onAction ? 'pointer' : 'auto' }} onClick={onAction} className={disable ? 'disable' : ''}>
        <div className="action-item-container">
          <div className="d-flex">
            <span>{prefix}</span> <div className="ml-8">{content}</div>
          </div>
        </div>
        {!isHiddenDivider && <Divider borderColor="border" />}
      </div>
    );
  };
  const listAction: Array<ActionItemProps> = useMemo(() => {
    const result = [
      {
        content: (
          <Text variant="bodyMd" as="p">
            Omega Chat is <b>{capitalizeFirstLetter(data?.data.plan || '')}</b>
          </Text>
        ),
        prefix: <Icon source={PlanIcon} tone="subdued" />,
      },
      {
        content: (
          <Text variant="bodyMd" as="p">
            <b>{data?.data.countGroupButtons}</b> widgets created
          </Text>
        ),
        prefix: <Icon source={ChannelsIcon} tone="subdued" />,
      },
      {
        content: (
          <Text variant="bodyMd" as="p">
            You have a total of <b>{data?.data.countButtons}</b> buttons
          </Text>
        ),
        prefix: <Icon source={ButtonIcon} tone="subdued" />,
      },
      {
        content: (
          <Text variant="bodyMd" as="p">
            Get <b>help and support</b>
          </Text>
        ),
        prefix: <Icon source={QuestionCircleIcon} tone="subdued" />,
        onAction: () => {
          try {
            $crisp.push(['do', 'chat:open']);
          } catch (error) {
            console.log(error);
          }
        },
      },
    ];
    return result;
  }, [data?.data.countButtons, data?.data.countGroupButtons, data?.data.plan]);

  const onDismissBanner = (key: BannerSliceKeys) => {
    dispatch(bannerSlice.actions.handleVisibleBanner({ key, value: false }));
  };

  return (
    <Layout title="Omega Chat">
      <HomeStyled>
        <RegularText>Let's build a premium chat experience for your leads</RegularText>

        <div className="mt-8">
          <HolidayBanner title="Notice: Office Closed for Holiday 📢">
            <p>
              Our team will be away <b>from 5 PM, August 30 until 9 AM, September 4 (GMT +7)</b> for Vietnam's National Day.
              Support will be available, but responses may be slower. Customization requests will be processed after the holiday.
              Thank you for your understanding, and Happy National Day! 🎉
            </p>
          </HolidayBanner>
        </div>

        <div className="mt-16 dashboard-container">
          <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
            <div className="dashboard-img">
              <img src={images.dashboard} alt="_blank" />
            </div>
            <Card>
              {isLoading ? (
                <SkeletonBodyText lines={6} />
              ) : (
                listAction.map((item, index) => {
                  return <ActionItems {...item} isHiddenDivider={listAction.length === index + 1} key={index} />;
                })
              )}
            </Card>
          </InlineGrid>
        </div>

        <div className="mt-16">
          <WarningBanner
            isVisible={isShowEmbedBanner && embeddedBanner}
            title="Enable the app embed on your theme"
            onDismiss={() => {
              dispatch(bannerSlice.actions.handleVisibleBanner({ key: 'embeddedBanner', value: false }));
            }}
            hiddenContactSupport
          >
            <RegularText>
              To ensure the Omega Chat works with your theme, please make sure to turn on the app in the Theme Editor.
            </RegularText>
            <div className="mt-8">
              <Button
                onClick={() => {
                  window.open(
                    `https://${config.shop}/admin/themes/current/editor?context=apps&activateAppId=${process.env.REACT_APP_THEME_APP_EXTENSION_ID}/omg-facebook-chat`,
                    '_blank',
                  );
                }}
              >
                Turn on
              </Button>
            </div>
          </WarningBanner>
        </div>

        <div className="mt-16">
          {data?.data.countGroupButtons === 0 ? (
            <Card padding={'400'}>
              <Table endTime={dashboard.endDate.getTime()} startTime={dashboard.startDate.getTime()} />
            </Card>
          ) : (
            <Analytics isInHomePage={true} />
          )}
        </div>

        {isVisibleThinkTodoNext && (
          <div className="mt-16">
            <ThingsToDoNext numberGroupButtons={data?.data.countButtons || 0} />
          </div>
        )}

        {isVisibleBlockify && (
          <div className="mt-16">
            <BannerTemplate onDismiss={() => onDismissBanner('isVisibleBannerBlockify')} src={images.blockify}>
              <BoldText>Make it easy to control store visitors and protect your store</BoldText>
              <div className="mt-16">
                <RegularText>
                  Blockify allows you to easily block visitors based on location and IP, reducing fraud and blocking bots. Analyze
                  traffic, identify risks, and enhance store protection with Visitor Analytics. Say goodbye to scammers - choose
                  Blockify for high security and peace of mind!
                </RegularText>
              </div>

              <div className="mt-16">
                <Button
                  variant="primary"
                  onClick={() => {
                    window.open(
                      'https://apps.shopify.com/blockify?utm_campaign=crs&utm_source=fbchat&utm_medium=homeinapp',
                      '_blank',
                    );
                  }}
                >
                  Try it now
                </Button>
              </div>
            </BannerTemplate>
          </div>
        )}

        {false && (
          <div className="mt-16">
            <BannerTemplate src={images.dashboardSynctrack} onDismiss={() => onDismissBanner('isVisibleBannerSynctrack')}>
              <BoldText>
                Auto-sync PayPal tracking info & Stripe.
                <br />
                Faster PayPal funds release and build trust with PayPal
              </BoldText>
              <div className="mt-16">
                <RegularText>
                  Synctrack adds tracking info to PayPal & Stripe automatically and instantly on autopilot. That helps you reduce
                  Stripe and PayPal disputes, money on hold, PayPal limits, and reserves. Synctrack keeps your business
                  transparent, saves time, and avoids PayPal disputes!
                </RegularText>
              </div>

              <div className="mt-16 d-flex">
                <Button
                  variant="primary"
                  onClick={() => {
                    window.open(
                      'https://apps.shopify.com/synctrack?utm_campaign=crs&utm_source=fbchat&utm_medium=homeinapp',
                      '_blank',
                    );
                  }}
                >
                  Try for free
                </Button>
                <div className="ml-8">
                  <Button onClick={openCrisp}>Discuss with our team</Button>
                </div>
              </div>
            </BannerTemplate>
          </div>
        )}

        <div className="mt-16">
          <CustomCard title={'Help center'}>
            <div className="homepage-help-center">
              <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap={'400'}>
                <Card>
                  <Link removeUnderline url="mailto:contact@omegatheme.com" target="_blank">
                    <Icon source={SendIcon} tone="info" /> Get email support
                  </Link>
                  <Text as="p">Email us, and we'll get back to you as soon as possible.</Text>
                </Card>
                <Card>
                  <Link removeUnderline onClick={openCrisp}>
                    <Icon source={ChatIcon} tone="info" />
                    Start live chat
                  </Link>
                  <Text as="p">Talk to us directly via live chat to get help with your question.</Text>
                </Card>
                <Card>
                  <Link removeUnderline url="" target="_blank">
                    <Icon source={SmileyHappyIcon} tone="info" />
                    Our help docs
                  </Link>
                  <Text as="p">Find a solution to your problem with our documents and tutorials.</Text>
                </Card>
              </InlineGrid>
            </div>
          </CustomCard>
        </div>

        {isVisibleRecommendApps ? (
          <div className="mt-16">
            <Card>
              <div className="apps-title">
                <BoldText>Other high-quality apps from Synctrack</BoldText>
                <div className="recommend-apps-controller">
                  <div onClick={() => window.open('https://synctrack.io/partners/', '_blank')}>
                    <Tooltip content="View all">
                      <Icon source={MenuHorizontalIcon} />
                    </Tooltip>
                  </div>

                  <div
                    className="ml-8"
                    onClick={() => {
                      dispatch(bannerSlice.actions.handleVisibleBanner({ key: 'isVisibleRecommendApps', value: false }));
                    }}
                  >
                    <Tooltip content="Dismiss">
                      <Icon source={XIcon} tone="base" />
                    </Tooltip>
                  </div>
                </div>
              </div>

              <div className="mt-16 apps-wrapper">
                <RecommendedApps />
              </div>
            </Card>
          </div>
        ) : null}
      </HomeStyled>
    </Layout>
  );
};

export default memo(HomePage);
