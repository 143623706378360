import { images } from '@/asset';

export const configRecommend = [
  {
    title: 'Omega Estimated Delivery Date',
    description: 'Reduces shipping date inquiries, increases customer satisfaction by showing estimated delivery date',
    logo: images.estimated_delivery,
    href: 'https://apps.shopify.com/omega-estimated-shipping-date?utm_source=fbchat&utm_medium=homeinapp&utm_campaign=crs',
    banner: images.banner_estimated_delivery,
    star: '4.8',
    plan: 'Free to install',
    // marketingBanner: 'ads_order_tracking',
    hasBFS: true,
  },
  {
    title: 'Synctrack ‑ Returns & Exchanges',
    hasBFS: true,
    brandURL: 'https://apps.shopify.com/partners/omegaapps',
    description: 'Streamline returns, exchanges, refunds to delight customers & retain revenue',
    logo: images.return_drive_app,
    href: 'https://apps.shopify.com/omega-returns-drive?utm_source=fbchat&utm_medium=homeinapp&utm_campaign=crs',
    banner: images.return_drive_banner_app,
    star: '4.9',
    plan: 'Free',
    // marketingBanner: 'ads_returnsdrive',
  },
];
