import styled from 'styled-components';

export const HolidayBannerStyled = styled.div`
  .Polaris-Banner.Polaris-Banner--withinPage {
    .Polaris-BlockStack .Polaris-Box {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;
