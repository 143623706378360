/* eslint-disable jsx-a11y/anchor-is-valid */
import RegularText from '@/components/RegularText';
import { Button, Card, Icon, InlineGrid, Link } from '@shopify/polaris';
import { StarFilledIcon } from '@shopify/polaris-icons';
import { memo } from 'react';
import { configRecommend } from './configRecommend';
const RecommendedApps = () => {
  return (
    <InlineGrid columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} gap={'400'}>
      {configRecommend.map((item, index) => {
        return (
          <Card key={index}>
            <div>
              <div className="recommended-apps-container">
                <img className="recommend-app-logo" src={item.logo} alt="recommend apps" />
                <div className="recommend-app-content">
                  <Link onClick={() => {}} removeUnderline url={item.href} external target="_blank">
                    {item.title}
                  </Link>
                  {item.hasBFS ? (
                    <div>
                      <span className="bfs-badge">
                        <svg width={12} height={10.5} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="m13 0-1 5-4 9 8-9.5L13 0ZM3 0l1 5 4 9-8-9.5L3 0Z" fill="#0D79A7" />
                          <path d="m3 0 1 5 4 9 4-9 1-5H3Z" fill="#49A3C9" />
                          <path d="M8 14 4 5l-4-.5L8 14ZM8 14l4-9 4-.5L8 14Z" fill="#07425B" />
                          <path d="M8 5.5 4 5l4 9 4-9-4 .5Z" fill="#0D79A7" />
                          <path d="m4 5 4-5 4 5-4.001.5L4 5Z" fill="#95D5F0" />
                          <path d="M4 5 3 0h5L4 5ZM12 5l1-5H8l4 5Z" fill="#49A3C9" />
                          <path d="M4 5 3 0 0 4.5 4 5ZM12 5l1-5 3 4.5-4 .5Z" fill="#0D79A7" />
                        </svg>
                        <span>Built for Shopify</span>
                      </span>
                    </div>
                  ) : null}
                  <div className="d-flex">
                    <Icon source={StarFilledIcon} tone="warning" />
                    <RegularText>
                      {item.star} - {item.plan}
                    </RegularText>
                  </div>
                  <RegularText>{item.description}</RegularText>
                </div>
              </div>
              <div className="recommend-app-btn">
                <Button variant="primary" url={item.href} onClick={() => {}} external fullWidth>
                  Try for free
                </Button>
              </div>
              <img className="recommend-app-banner" src={item.banner} alt="recommend app banner" />
            </div>
          </Card>
        );
      })}
    </InlineGrid>
  );
};

export default memo(RecommendedApps);
