import { HolidayBannerStyled } from '@/components/HolidayBanner/styled';
import { Banner } from '@shopify/polaris';
import { ReactNode, useEffect, useState } from 'react';

interface IProps {
  children: ReactNode;
  title: string;
}

export default function HolidayBanner(props: IProps) {
  const [isShow, setIsShow] = useState(false);

  const handleHideBanner = () => {
    sessionStorage.setItem('ot_chat_is_hide_holiday_banner', 'true');
    setIsShow(false);
  };

  useEffect(() => {
    if (!sessionStorage.getItem('ot_chat_is_hide_holiday_banner')) {
      setIsShow(true);
    }
  }, []);

  return isShow ? (
    <HolidayBannerStyled>
      <Banner title={props.title} onDismiss={handleHideBanner}>
        {props.children}
      </Banner>
    </HolidayBannerStyled>
  ) : null;
}
